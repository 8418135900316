.main-page {
  margin-left: 20px;
  margin-right: 20px;
}

.title {
  margin-bottom: 80px;

  font-size: 32px;
  font-family: 'SVN-Gilroy-Bold';
  line-height: 140%;

  text-align: center;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #172B4D;
}

@media only screen and (min-width : 1025px) {
  .main-page {
    margin-left: 11%;
    margin-right: 11%;
  }
}