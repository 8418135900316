.self-description {
  text-align: center;
}

.self-description--title {
  margin-top: 24px;

  font-family: 'SVN-Gilroy-Bold';
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #8A4C51;
}

.self-description--name {
  margin-top: 8px;

  font-family: 'SVN-Gilroy-Bold';
  font-size: 28px;
  line-height: 140%;

  color: #333333;
}

.self-description--description {
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 26px;

  color: #6B778C;
}

.self-description--picture {
  width: 100%;
  height: auto;
}

.self-description--picture_cover {
  width: 83.33%;
  margin: auto
}

@media only screen and (min-width : 1025px) {
  .self-description--picture_cover {
    margin: 0px;
  }

  .self-description {
    text-align: left;
    width: 30%;
  }

  .self-description--description {
    margin-top: 12px;
    margin-bottom: 0px;
  }

  .self-description--title {
    margin-top: 32px;
  }
}