.intro {
  flex-grow: 1;
  text-align: center;
}

.intro__logo {
  width: 100%;
  height: 100%;
}

.intro__logo-cover {
  width: 190px;
  height: 170px;
  margin: auto;
  display: inline-block;
}

.intro__introduce {
  width: 100%;

  text-align: start;
  font-family: 'SVN-Gilroy-Bold';
  font-size: 36px;
  line-height: 125%;
  letter-spacing: -0.01em;
  color: #172B4D;
}

.intro__conclusion {
  display: block;
  margin-top: 20px;
}

.intro__join-us {
  width: 100%;
  display: inline-flex;
  box-sizing: border-box;
  background: #8A4C51;
  height: 52px;
  margin-top: 72px;
  font-family: 'SVN-Gilroy-Bold';
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  text-decoration: none;
  color: #FFFFFF;
  
  align-items: center;
  justify-content: center;
}

.intro__join-us-arrow {
  margin-left: 20px;
}

@media only screen and (min-width : 1025px) {
  .intro {
    width: 50%;
    text-align: start;
  }

  .intro__introduce {
    width: 83.33%;
  }

  .intro__logo-cover {
    margin: 0px;
  }

  .intro__join-us {
    width: 33.33%;
  }
}