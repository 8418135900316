.carousel {
  flex-grow: 1;
  display: flex;
  flex-direction: column-reverse;
}

.carousel__indicator-list {
  list-style-type: none;
  padding: 9px;
  margin: 0px;
  display: flex;
  flex-direction: row-reverse;
}

.carousel__indicator {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 25px;
  flex-direction: row-reverse;
  align-self: flex-end;
  flex-shrink: 0;
}

.carousel__counter {
  display: inline-block;
  transform-origin: top left;

  font-family: 'SVN-Gilroy-Bold';
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.08em;
  color: #172b4d;
}

.carousel__counter-total {
  opacity: 0.6;
}

.carousel__indicator-element {
  cursor: pointer;
  width: 62px;
  height: 2px;
  background: #344563;
  opacity: 0.1;
  border-radius: 4px;
}

.carousel__indicator-element--active {
  opacity: unset;
}

.carousel__arrows {
  display: flex;
  flex-direction: row-reverse;
}

.carousel__arrow {
  display: block;
  border: 0px;
  padding: 0px;
  cursor: pointer;
  outline: none;
  background: unset;
  transform: rotate(90deg);
}

.carousel__arrow--up {
  margin-left: 35px;
}

.carousel__image-cover{
  margin-top: 25px;
  align-self: flex-end;
}

.carousel__image {
  width: 100%;
  height: auto;
}

@media only screen and (min-width : 1025px) {
  .carousel {
    width: 50%;
    flex-direction: row;
  }

  .carousel__arrow--down {
    margin-top: 40.5px;
  }

  .carousel__arrow--up {
    margin-left: 0px;
  }

  .carousel__arrow {
    transform: unset;
    text-align: start;
  }

  .carousel__arrows {
    margin-top: 52px;
    justify-content: flex-start;
    flex-direction: column;
  }

  .carousel__indicator-element {
    width: 2px;
    height: 35px;
  }

  .carousel__counter {
    transform: rotate(-90deg);
  }

  .carousel__indicator {
    flex-direction: column;
    width: unset;
  }

  .carousel__indicator-list {
    margin: 0px 0px 0px 7px;
    padding: 0px;
    flex-direction: column;
  }

  .carousel__image-cover{
    max-width: 750px;
    min-width: 450px;
  }
} 