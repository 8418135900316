.mile-stone {
  display: flex;
  justify-content: space-between;
}

.mile-stone--node-node {
  float: right;
}

.mile-stone--node-line {
  height: 100%;
  background: #8A4C51;
  opacity: 0.2;
  width: 2px;
  margin-top: 11px;
  margin-left: 50%;
}

.mile-stone--detail {
  width: 83.33%;
  flex-shrink: 0;
  margin-bottom: 50px;
}

.mile-stone--date {
  font-family: 'SVN-Gilroy-Bold';
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  color: #8A4C51;
}

.mile-stone--title {
  margin-top: 8px;

  font-family: 'SVN-Gilroy-Bold';
  font-size: 28px;
  line-height: 130%;

  color: #172B4D;
}

.mile-stone--content {
  margin-top: 12px;
  font-weight: normal;
  font-size: 16px;
  line-height: 162%;

  color: #6B778C;
}

.mile-stone--place-holder {
  display: none;
}

.mile-stone--end .mile-stone--detail {
  margin-bottom: unset;
}


@media only screen and (min-width : 1025px) {
  .mile-stone--place-holder {
    display: unset;
  }

  .mile-stone--left {
    flex-direction: row-reverse;
  }

  .mile-stone--place-holder {
    width: 37.5%;
    flex-shrink: 0;
  }

  .mile-stone--detail {
    width: 37.5%;
  }

  .mile-stone--end .mile-stone--node-line {
    display: none;
  }

  .mile-stone--end {
    justify-content: center;
    flex-wrap: wrap;
    margin-left: 25%;
    margin-right: 25%;
    text-align: center;
  }

  .mile-stone--end .mile-stone--detail {
    margin-top: 40px;
    width: 100%;
  }
}