body {
    margin: 0px;
    font-family: 'SVN-Gilroy';
}

@font-face {
    font-family: 'SVN-Gilroy';
    src: url('resources/font/SVN-Gilroy-Regular.otf') format('truetype');
}

@font-face {
    font-family: 'SVN-Gilroy-Bold';
    src: url('resources/font/SVN-Gilroy-SemiBold.otf') format('truetype');
}

@font-face {
    font-family: 'FS-AmeliaScript';
    src: url('resources/font/FS-AmeliaScript-FineVersion.otf') format('truetype');
}